// import 'vite/modulepreload-polyfill';

import * as gridjs from 'gridjs'
import Alpine from 'alpinejs'
import htmx from 'htmx.org'
import morph from '@alpinejs/morph'
import focus from '@alpinejs/focus'
import ui from '@alpinejs/ui'

import axios from 'axios'

import { autocomplete } from '@algolia/autocomplete-js'

import { Splide } from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

window.htmx = htmx
window.gridjs = gridjs
window.axios = axios
window.autocomplete = autocomplete
window.Splide = Splide
window.AutoScroll = AutoScroll

window.Alpine = Alpine
Alpine.plugin(morph)
Alpine.plugin(focus)
Alpine.plugin(ui)
Alpine.start()

window.htmx.config.getCacheBusterParam = true
